import React, { useState } from "react";
import styles from "./styles.module.css";
import { SelectField, TextAreaField, Button, Icon } from '@aws-amplify/ui-react';
import { SendMessageModal } from './';
import { post } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';

async function postTodo(role, message, user_id, conversation_id) {
    try {
        const restOperation = post({
            apiName: 'openai',
            path: '/chat',
            options: {
                body: {
                    message: message,
                    role: role,
                    user_id: user_id,
                    conversation_id: conversation_id
                }
            }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (body) {
            const data = response;
            console.log('POST call succeeded: ', data);
            return data;
        } else {
            console.log('Response did not contain a body.');
            return "failed";
        }
    } catch (error) {
        console.log('POST call failed: ', error);
        return "failed";
    }
};

const user_id = uuidv4();
const conversation_id = uuidv4();

export default function MainContent() {
    const defaultData = [
        [
            { label: "clarity_start", value: 0 },
            { label: "clarity_end", value: 100 },
        ],
        [
            { label: "conciseness_start", value: 0 },
            { label: "conciseness_end", value: 100 },
        ],
        [
            { label: "accuracy_start", value: 0 },
            { label: "accuracy_end", value: 100 },
        ],
    ];
    const frame = '/frame.png'
    const [isSendMessageClicked, setSendMessageClicked] = useState(false);
    const toggleSendMessageModal = () => {
        setSendMessageClicked(!isSendMessageClicked);
    };
    const [role, setRole] = useState('data_analyst');
    const [message, setMessage] = useState('');
    const [scores, setScores] = useState(defaultData);
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const [responseData, setResponseData] = useState("Loading...");
    const fetchData = async () => {
        setResponseData("Loading...")
        setScores(defaultData)
        toggleSendMessageModal();
        const data = await postTodo(role, message, user_id, conversation_id);
        if (data.error) {
            setResponseData(data.error);
        } else {
            setResponseData(data.response);
            setScores(data.scores);
        }
    };
    return (
        <>
            {isSendMessageClicked && <SendMessageModal scores={scores} message={message} reply={responseData} role={role} onClose={toggleSendMessageModal} />}
            <div className={styles.mainContentContainer}>
                <div className={styles.mainContentTextContainer}>
                    <h1 className={styles.mainContentTitle}>Ace your interviews effortlessly</h1>
                    <h2 className={styles.mainContentSubTitle}>Practice with our AI recruiter and get instant feedback</h2>
                    <h3 className={styles.mainContentCTAText}>Give it a shot</h3>
                    <div className={styles.mainContentSelectText}>
                        <p className={styles.mainContentStaticText}>You are interviewing for the role of a</p>
                        <SelectField
                            variation="quiet"
                            className={styles.inlineSelect}
                            style={{
                                borderBottom: "1.5px solid white",
                            }}
                            inputStyles={{
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                color: 'white',
                                fontSize: '0.8vw',
                            }}
                            onChange={handleRoleChange}
                            alignSelf="flex-end"
                            icon="*"
                            iconColor="white"
                        >
                            <option value="data_analyst">Data Analyst</option>
                            <option value="data_scientist">Data Scientist</option>
                            <option value="front_end_engineer">Front-End Engineer</option>
                            <option value="backend_engineer">Back-End Engineer</option>
                        </SelectField>
                    </div>
                    <div className={styles.mainContentTextArea}>
                        <TextAreaField
                            rows={5}
                            isRequired={true}
                            placeholder="Why should we hire you?"
                            style={{ overflowWrap: "break-word" }}
                            grow='1'
                            maxLength={500}
                            fontWeight="600"
                            onChange={handleMessageChange}
                            inputStyles={{
                                color: '#2f2f2f',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                borderColor: 'white'
                            }}
                        />
                        <Button
                            marginLeft="-45px"
                            marginBottom="0.2vw"
                            fontFamily="Sora"
                            fontWeight="400"
                            alignSelf="flex-end"
                            borderRadius="100%"
                            width="40px"
                            height="40px"
                            justifyContent="center"
                            isDisabled={false}
                            variation='primary'
                            className={styles.mainContentSubmitButton}
                            style={{ zIndex: 11, backgroundColor: "rgb(3, 123, 249)" }}
                            onClick={fetchData}
                        >
                            <Icon
                                height="20px"
                                viewBox={{ "minX": 7, "minY": 4 }}
                                paths={[{ "d": "M4.23652 5.025L10.4949 7.70833L4.22819 6.875L4.23652 5.025V5.025ZM10.4865 12.2917L4.22819 14.975V13.125L10.4865 12.2917V12.2917ZM2.56986 2.5L2.56152 8.33333L15.0615 10L2.56152 11.6667L2.56986 17.5L20.0615 10L2.56986 2.5Z", "fill": "white", "fillRule": "nonzero" }]}
                                display="block"
                                position="relative"
                                marginLeft="15px"
                                marginBottom="-9px"
                                padding="0"
                                style={{ zIndex: 10 }}
                            />
                        </Button>
                    </div>
                    <p className={styles.mainContentHelperText}>How does it work?</p>
                </div>
                <img className={styles.mainContentImage} src={frame} alt="A chat between a user and an AI agent" />
            </div>
        </>
    )
}
