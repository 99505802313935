import React from "react";
import styles from "./styles.module.css";
import { Button } from '@aws-amplify/ui-react';

export default function Navbar({ isSignUpModalOpen }) {
    const logo = '/logo.png'
    return (
        <div className={styles.navbar}>
            <img className={styles.imageLogo} src={logo} alt="Hiring genie logo" />
            <div className={styles.buttonGroup}>
                <Button onClick={isSignUpModalOpen} className={styles.signUpButtonFormat} variation='primary'>Sign up</Button>
                <Button disabled={true} className={styles.logInButtonFormat} variation='secondary'>Log in</Button>
            </div>
        </div>
    )
}
