import React, { useState } from 'react';
import { Navbar, VideoOverlay, MainContent, SignUpModal } from '../hg-ui-components';
import styles from "./styles.module.css";
import '@aws-amplify/ui-react/styles.css';
import { Helmet } from "react-helmet";

function Home() {
    const [isSignUpClicked, setSignUpClicked] = useState(false);
    const toggleModal = () => {
        setSignUpClicked(!isSignUpClicked);
    };
    return (
        <>
            <Helmet>
                <title>Hiring Genie</title>
            </Helmet>
            <div className={styles.appContainer}>
                {isSignUpClicked && <SignUpModal onClose={toggleModal} />}
                <VideoOverlay />
                <Navbar isSignUpModalOpen={toggleModal} />
                <MainContent />
            </div>
        </>
    )
}

export default Home;
