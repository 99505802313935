import React from "react";
import styles from "./styles.module.css";

export default function Layout404() {
    const Image404 = '/404.png'
    return (
        <div className={styles.Layout404}>
            <h1 className={styles.Layout404Title}>This is not the page you're looking for</h1>
            <h1 className={styles.Layout404SubTitle}>404</h1>
            <img className={styles.Layout404Image} src={Image404} alt="An astronaut lost in space" />
        </div>
    )
}
