import Home from './routes/Home'
import NotFound from './routes/NotFound'
import '@aws-amplify/ui-react/styles.css'; // ** this is missed importing **

import { BrowserRouter, Routes, Route } from 'react-router-dom';


export default function Example() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
