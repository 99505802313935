import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { Icon } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './../amplifyconfiguration.json';
import { HiOutlineDatabase, HiUsers, HiPencil, HiLightningBolt, HiLightBulb, HiOutlineEmojiHappy, HiOutlineInformationCircle } from "react-icons/hi";
import { CONVERSATION_MODAL_TEXT } from './constants'
import { DonutChart } from './'

Amplify.configure(config);

function formatTitle(string) {
    return string
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export default function SendMessageModal({ scores, message, reply, role, onClose }) {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]); // This effect depends on role and message, so it re-runs if they change.


    return (
        <>
            <div className={styles.modalContainer}>
                <div className={styles.modalSendMessageContentContainer}>
                    <span onClick={onClose} className={styles.closeIcon} >
                        <Icon
                            ariaLabel="Close"
                            pathData="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                        />
                    </span>
                    <div className={styles.openAIMessageContainer}>
                        <HiLightningBolt className={styles.openAIIcon} />
                        <div className={styles.openAIMessage}>
                            <p>{CONVERSATION_MODAL_TEXT.intro}</p>
                            <p>{CONVERSATION_MODAL_TEXT.introLineTwo}</p>
                            <p> </p>
                            <p>{CONVERSATION_MODAL_TEXT.introLineThree}</p>
                        </div>
                    </div>
                    <p className={styles.userMessage}>{formatTitle(role)}</p>
                    <div className={styles.openAIMessageContainer}>
                        <HiLightningBolt className={styles.openAIIcon} />
                        <div className={styles.openAIMessageConversationContainer}>
                            <div className={styles.openAIMessageTextContainer}>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.greeting}</p>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.greetingLineTwo}</p>
                            </div>
                            <div className={styles.openAIMessageTextContainer}>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.choice}</p>
                                <div className={styles.interviewChoiceContainer}>
                                    <div className={styles.interviewChoiceButtonActive}>
                                        <p>Behavioral</p>
                                        <HiUsers />
                                    </div>
                                    <div className={styles.interviewChoiceButton}>
                                        <p>Technical</p>
                                        <HiOutlineDatabase />
                                    </div>
                                    <div className={styles.interviewChoiceButton}>
                                        <p>Other</p>
                                        <HiPencil />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.openAIMessageTextContainer}>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.firstQuestion}</p>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.firstQuestionLineTwo}</p>
                                <p className={styles.openAIMessageTextFormat}>{CONVERSATION_MODAL_TEXT.firstQuestionLineThree}</p>
                            </div>
                        </div>
                    </div>
                    <p className={styles.userMessage}>{message}</p>
                    <div className={styles.ActionIconsContainer}>
                        <HiLightBulb className={styles.ActionIconsInsightActive} />
                        <HiOutlineEmojiHappy className={styles.ActionIconsInsight} />
                        <DonutChart
                            className={styles.donutGraph}
                            data={scores}
                            name="donutChart"
                        />
                    </div>
                    <div className={styles.openAIMessageInsightContainer}>
                        <HiLightBulb className={styles.openAIMessageInsightIcon} />
                        <div className={styles.openAIMessageInsightTextContainer}>
                            <p className={styles.openAIMessageInsightTextFormat}>{reply}</p>
                        </div>
                    </div>
                </div>
                <div onClick={onClose} className={styles.modalOverlay}></div>
            </div >
        </>
    );
}
