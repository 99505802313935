/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSignUps = /* GraphQL */ `
  mutation CreateSignUps(
    $input: CreateSignUpsInput!
    $condition: ModelSignUpsConditionInput
  ) {
    createSignUps(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSignUps = /* GraphQL */ `
  mutation UpdateSignUps(
    $input: UpdateSignUpsInput!
    $condition: ModelSignUpsConditionInput
  ) {
    updateSignUps(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSignUps = /* GraphQL */ `
  mutation DeleteSignUps(
    $input: DeleteSignUpsInput!
    $condition: ModelSignUpsConditionInput
  ) {
    deleteSignUps(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
