import React, { useEffect } from 'react';
import * as d3 from 'd3';

function DonutChart(props) {
    const {
        data,
        name,
        className,
    } = props;

    const width = 40;
    const height = 40;

    const dataModel = [
        {
            colorScale: ["#FAC882", "#FDEFD9"],
            innerRadius: 7,
            outerRadius: 10
        }, {
            colorScale: ["#FA8C8C", "#FDDDDD"],
            innerRadius: 12,
            outerRadius: 15
        },
        {
            colorScale: ["#0075FF", "#CCE3FF"],
            innerRadius: 17,
            outerRadius: 20
        }
    ]

    useEffect(() => {
        drawChart();
    }, [data]);

    function drawChart() {
        // Remove the old svg
        d3.select('#' + name)
            .select('svg')
            .remove();

        // Create new svg
        const svg = d3
            .select('#' + name)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        dataModel.forEach((layer, i) => {
            const currentData = data[i]; // Get the data for the current layer

            const arcGenerator = d3.arc()
                .innerRadius(layer.innerRadius)
                .outerRadius(layer.outerRadius);

            const pieGenerator = d3.pie()
                .sort(null) // Maintain the original order of the data
                .value(d => d.value);

            // Generate the arcs for the current layer's data
            const arcs = pieGenerator(currentData);

            // Bind arcs to path elements and set their attributes for the current layer
            svg.selectAll(`.arc-${i}`)
                .data(arcs)
                .enter()
                .append('path')
                .attr('class', `arc-${i}`)
                .attr('d', arcGenerator)
                .style('fill', (d, index) => layer.colorScale[index % layer.colorScale.length]) // Cycle through colorScale
                .style('stroke', '#ffffff')
                .style('stroke-width', 1);
        });
    }

    return <div id={name} className={className} />;
}

export default DonutChart;