import React from "react";
import styles from "./styles.module.css";
import videoBackground from '../assets/video.mp4';

export default function VideoOverlay() {
    return (
        <>
            <div className={styles.videoContainer}>
                <video playsInline loop autoPlay muted className={styles.backgroundVideo}>
                    <source src={videoBackground} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className={styles.videoOverlay}></div>
            </div>
        </>
    );
}
