import { Layout404 } from './../hg-ui-components';
import '@aws-amplify/ui-react/styles.css';
import { Helmet } from "react-helmet";

function NotFound() {
    return (
        <>
            <Helmet>
                <title>Hiring Genie</title>
            </Helmet>
            <Layout404 />
        </>
    )
}

export default NotFound;
